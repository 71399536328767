import { useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import LoadingUIView from 'common/components/ui/loading-ui'

const Login = () => {
  const { status } = useSession()
  const { query } = useRouter()
  const { callbackUrl } = query

  useEffect(() => {
    callbackUrl &&
      signIn('smartcentral', { callbackUrl: callbackUrl as string })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackUrl])

  return (
    <LoadingUIView
      title={status === 'loading' ? 'Loading page' : 'Authorising'}
    />
  )
}

export default Login
